@import "App";

$primary: #E13527;

* {
    box-sizing: border-box;
    font-family: "custom-Geogrotesque";
}

.home {
    background: #f0f0f0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    .intro {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background: #18191A;
        opacity: 0.9;
        color: #fff;
        display: none;
        z-index: 1000;

        .intro-positioner {
            text-align: center;
            position: relative;
            z-index: 1000;
        }

        .intro-title {
            font-size: 80px;
            font-weight: 400;
            line-height: 88px;
            margin: 0 0 24px 0;
            text-transform: uppercase;

            @media (max-width: 480px) {
                font-size: 45px;
                line-height: 50px;
            }

            small {
                display: block;
                margin: 0 0 8px 0;
                font-size: 20px;
                line-height: 28px;
                color: #E13527;
                text-transform: uppercase;
                animation-delay: 100ms;
            }

            div {
                animation-delay: 400ms;
            }
        }

        .intro-body {
            animation-delay: 600ms;
        }

        p {
            font-size: 24px;
            line-height: 32px;
            opacity: 0.7;
            margin: 0 auto 80px auto;
            width: 100%;

            @media (max-width: 480px) {
                margin-bottom: 30px;
                padding: 0 30px;
            }
        }

        .play-icon {
            width: 120px;
            height: 120px;
            display: flex;
            margin: 0 auto 44px auto;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 3px solid rgba(#D8D8D8, 0.7);
            transition: all 100ms ease-in-out;
            cursor: pointer;

            @media (max-width: 480px) {
                width: 90px;
                height: 90px;

                img {
                    margin-left: 10px;
                    width: 30px;
                    height: auto;
                }
            }


            &:hover {
                border-color: rgba(#fff, 1);
                transform: scale(1.06);
            }
        }

        a.video-link {
            color: $primary;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto 0 auto;
            text-decoration: none;
            text-transform: uppercase;

            img {
                margin-left: 25px;
            }
        }

        .intro-backdrop {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 110;
            background: rgba(#212121, 0.85);
        }

        .image-wrapper {
            position: absolute;
        }

        .nav-link {
            background: #E13527;
            color: white;
            height: 72px;
            line-height: 72px;
            text-transform: uppercase;
            padding: 0 60px;
            font-size: 18px;
            font-weight: 600;
            display: inline-block;
            text-decoration: none;
            position: relative;
            overflow: hidden;

            .nav-link-text {
                transition: all 200ms 200ms ease-in-out;
            }

            .nav-link-arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(-100%, -50%);
                transition: all 300ms ease-in-out;

                .nav-link-arrow-line {
                    display: inline-block;
                    width: 124px;
                    height: 2px;
                    background-size: 100% auto;
                }

                .nav-link-arrow-icon {
                    position: relative;
                    margin-left: -10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: -1;
                    transition: all 200ms ease-in-out;
                    opacity: 0;
                }
            }

            &:hover {
                .nav-link-text {
                    opacity: 0;
                    transition: all 200ms ease-in-out;
                }

                .nav-link-arrow {
                    left: 50%;
                    transform: translate(-50%, -50%);

                    .nav-link-arrow-icon {
                        transition: all 200ms 200ms ease-in-out;
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }
        }
    }

    .bottom-left-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 32px;
        left: 32px;
    }

    .action-button {
        height: 60px;
        white-space: nowrap;
        background: #EDEDED;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        cursor: pointer;
        padding: 0 24px;
        margin-right: 15px;
        color: inherit;
        text-decoration: none;
        border: 0;

        img {
            height: 20px;
            width: 20px;
        }

        span {
            font-size: 18px;
            line-height: 60px;
            margin-left: 12px;
            color: #212121;
            display: inline-block;
            user-select: none;
        }

        &:hover {
            background: #D6D6D6;
        }

        &.button-back {
            position: absolute;
            top: 32px;
            left: 32px;
        }

        &.button-without-icon {
            span {
                margin-left: 0px;
            }
        }
    }

    .sidebar {
        background: #fff;
        position: absolute;
        top: 32px;
        right: 32px;
        bottom: 32px;
        width: 520px;
        padding: 60px;
        z-index: 10;
        display: flex;
        flex-direction: column;

        .loader {
            background-color: white;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            height: 2px;
            visibility: hidden;
            overflow: hidden;            

            &.active {
                visibility: unset;
            }
        }

        .loader.active:before {
            display: block;
            position: absolute;
            content: "";
            left: -200px;
            width: 200px;
            height: 4px;
            background-color: $primary;
            animation: loading 1.5s linear infinite;
          }

          @keyframes loading {
            from {left: -200px; width: 30%;}
            50% {width: 30%;}
            70% {width: 70%;}
            80% { left: 50%;}
            95% {left: 120%;}
            to {left: 100%;}
        }

        .sidebar-content {
            flex: 1;
            overflow-y: scroll;
            margin-bottom: 20px;
        }

        .sidebar-content-action {
            .button {
                display: block;
                width: 100%;
                appearance: none;
                border: 0;
                outline: 0;
                box-shadow: 0;
                border-radius: 0;
                font: inherit;
                height: 60px;
                line-height: 60px;
                background: $primary;
                color: #fff;
                font-size: 16px;
                cursor: pointer;
            }
        }
    }

    .navigation {
        position: absolute;
        top: 32px;
        bottom: 32px;
        width: 350px;
        right: 202px;
        background: $primary;
        color: #fff;
        z-index: 2;
        transition: transform 300ms ease-in-out;

        .navigation-toggler {
            appearance: none;
            border: 0;
            outline: 0;
            box-shadow: 0;
            border-radius: 0;
            font: inherit;
            height: 60px;
            width: 60px;
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(-60px);
            background: $primary;
            transition: all 300ms ease-in-out;

            .bar {
                display: block;
                width: 20px;
                height: 1px;
                background: #fff;
                position: absolute;
                left: 50%;
                top: 50%;
                transition: all 300ms ease-in-out;

                &:nth-child(1) {
                    transform: translate(-50%, -8px);
                }

                &:nth-child(2) {
                    transform: translate(-50%, -50%);
                }

                &:nth-child(3) {
                    transform: translate(-50%, 7px);
                }
            }
        }

        .navigation-list {
            list-style: none;
            margin: 60px 0 0 0;
            padding: 0;

            li {
                display: block;
                margin: 0;

                a {
                    display: flex;
                    height: 104px;
                    // line-height: 104px;
                    background: $primary;
                    align-items: center;
                    padding: 0 44px;
                    cursor: pointer;
                    text-decoration: none;

                    .label {
                        color: #fff;
                        font-size: 18px;
                    }

                    .icon {
                        width: 40px;
                    }
                }

                &.active {
                    a {
                        background: #fff;
                        
                        .label {
                            color: $primary;
                        }
                    }
                }
            }
        }

        &.open {
            transform: translateX(-100%);

            .navigation-toggler {
                transform: translateX(0px);
                background: rgba($primary, 0);

                .bar {
                    width: 26px;

                    &:nth-child(1) {
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }

                    &:nth-child(2) {
                        transform: translate(-50%, -50%);
                        opacity: 0;
                    }

                    &:nth-child(3) {
                        transform: translate(-50%, -50%) rotate(45deg);
                    }
                }
            }
        }
    }

    .architecture-list {
        list-style: none;
        margin: 0;
        padding: 0;
        overflow: hidden;

        li {
            display: inline-block;
            width: 180px;
            margin-right: 40px;
            margin-bottom: 44px;
            cursor: pointer;
            position: relative;

            &:nth-child(2n) {
                margin-right: 0;
            }

            .active-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 120px;
                background: rgba(#18191A, 0.5);

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 25px;
                    height: 16px;
                }
            }

            img {
                width: 180px;
                height: 120px;
                margin-bottom: 12px;
            }

            .label {
                text-align: center;
                font-size: 16px;
                color: #1E1E1E;
                line-height: 24px;
            }

            &.active {
                .label {
                    color: $primary;
                }
            }
        }
    }

    .image-wrapper {
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 0;

        .image {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100vw;
            height: 100vh;
            object-fit: cover;
            z-index: 1;
        }
    }

    &.show-intro {
        .intro {
            display: flex;
        }
    }

    .selected-product-name {
        margin: 0 0 15px 0;
        font-size: 18px;
        line-height: 24px;
    }

    .products-list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            margin: 0 0 16px 0;
            padding: 32px 50px 56px 36px;
            border: 1px solid rgba(#E13527, 0.2);
            position: relative;
            cursor: pointer;
            color: #404040;

            .product-name {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 4px;
            }

            .product-patterns-count {
                font-size: 16px;
                line-height: 24px;
            }

            .product-arrow-right {
                position: absolute;
                bottom: 30px;
                right: 34px;
            }

            &.active {
                background: $primary;
                border-color: $primary;
                color: #fff;
            }
        }
    }

    .patterns-list {
        list-style: none;
        margin: 0;
        padding: 0;
        overflow: hidden;

        li {
            margin: 0;
            display: flex;
            height: 100px;
            align-items: center;
            position: relative;
            cursor: pointer;

            .pattern-image {
                height: 64px;
                width: 64px;
                border-radius: 50%;
                margin-right: 24px;
            }

            .active-overlay {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 64px;
                height: 64px;
                border-radius: 50%;
                background: rgba(#18191A, 0.5);
                margin-top: -3px;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 25px;
                    height: 16px;
                }
            }

            .pattern-name { 
                font-size: 18px;
                line-height: 24px;
            }

            &.active {
                .pattern-name {
                    color: $primary;
                }
            }

            .closeup-button {
                height: 44px;
                margin-left: auto;
                background: #fff;
                border: 1px solid $primary;
                padding: 0 20px;
                border-radius: 28px;
                position: relative;
                overflow: hidden;
                display: none;

                .closeup-button-text {
                    transition: all 200ms 100ms ease-in-out;
                    font-size: 16px;
                    line-height: 44px;
                    color: #404040;
                    font-weight: 500;
                }

                .closeup-button-arrow {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate(-100%, -50%);
                    transition: all 300ms ease-in-out;

                    .closeup-button-arrow-line {
                        display: inline-block;
                        width: 32px;
                        height: 2px;
                        background-size: 100% auto;
                    }

                    .closeup-button-arrow-icon {
                        position: relative;
                        margin-left: -10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: -1;
                        transition: all 200ms ease-in-out;
                        opacity: 0;
                    }
                }

                &:hover {
                    background: $primary;

                    .closeup-button-text {
                        opacity: 0;
                        transition: all 200ms ease-in-out;
                    }

                    .closeup-button-arrow {
                        left: 50%;
                        transform: translate(-50%, -50%);

                        .closeup-button-arrow-icon {
                            transition: all 200ms 200ms ease-in-out;
                            opacity: 1;
                            z-index: 1;
                        }
                    }
                }
            }

            &:hover {
                .closeup-button {
                    display: block;
                }
            }
        }
    }
}

.export-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    display: flex; 
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.60);

    .export-modal {
        width: 500px;
        border: 1px solid rgb(204, 204, 204); 
        background: rgb(255, 255, 255); 
        overflow: auto; 
        outline: none; 
        display: flex;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;

        .export-modal-content {
            text-align: center;
            margin-top: 25px;
            margin-bottom: 25px;

            div {
                padding: 15px;
            }
            
            .export-header {
                color: $primary;
                font-size: 22px;
                text-transform: uppercase;                
            }

            .export-options {

                display: flex;
                align-items: center;
                justify-content: center;

                .button {
                    display: block;
                    border: 0;
                    outline: 0;
                    box-shadow: 0;
                    border-radius: 0;
                    font: inherit;
                    height: 60px;
                    line-height: 60px;
                    background: $primary;
                    color: #fff;
                    font-size: 16px;
                    min-width: 150px;
                    cursor: pointer;
                    
                    &:disabled {
                        cursor: not-allowed;
                    }
                }

                .button-pdf {
                    margin-right: 25px;
                }
            }            
        }
    }

}

.closeup-modal-overlay {
    box-shadow: 0;
    outline: 0;
    border: 0;

    .close-modal {
        position: fixed;
        top: 0;
        right: 0;
        background: $primary;
        z-index: 10000;
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .closeup-modal {
        .closeup {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            box-shadow: 0;
            z-index: 1000;
            outline: 0;
            border: 0;
            background-color: #fff;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }
}

@media (max-width: 480px) {
    .ReactModal__Content {
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        padding: 0 !important;

        a {
            display: block;
            margin: 20px 20px -20px 20px;
        }
    }
}