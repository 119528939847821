body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fade-in-image {
  opacity: 0 !important;
  transition: opacity 500ms ease-in !important; }
  .fade-in-image.fade-in-image-loaded {
    opacity: 1 !important; }

@font-face {
  font-family: "custom-Geogrotesque";
  src: url(/static/media/2DF53C_0_0.ea1266c0.eot);
  src: url(/static/media/2DF53C_0_0.ea1266c0.eot?#iefix) format("embedded-opentype"), url(/static/media/2DF53C_0_0.d2cb9b93.svg) format("svg"), url(/static/media/2DF53C_0_0.ef73a9ff.woff2) format("woff2"), url(/static/media/2DF53C_0_0.c2384a76.woff) format("woff"), url(/static/media/2DF53C_0_0.57aaaae1.ttf) format("truetype");
  font-weight: 300; }

@font-face {
  font-family: "custom-Geogrotesque";
  src: url(/static/media/2DF53C_1_0.7c7825ee.eot);
  src: url(/static/media/2DF53C_1_0.7c7825ee.eot?#iefix) format("embedded-opentype"), url(/static/media/2DF53C_1_0.e8d2af0e.svg) format("svg"), url(/static/media/2DF53C_1_0.f2b691fe.woff2) format("woff2"), url(/static/media/2DF53C_1_0.5d6aec34.woff) format("woff"), url(/static/media/2DF53C_1_0.a4d288c5.ttf) format("truetype");
  font-weight: 400; }

@font-face {
  font-family: "custom-Geogrotesque";
  src: url(/static/media/2DF53C_2_0.ad0f1f47.eot);
  src: url(/static/media/2DF53C_2_0.ad0f1f47.eot?#iefix) format("embedded-opentype"), url(/static/media/2DF53C_2_0.6f2f2d58.svg) format("svg"), url(/static/media/2DF53C_2_0.ec28c55e.woff2) format("woff2"), url(/static/media/2DF53C_2_0.66866266.woff) format("woff"), url(/static/media/2DF53C_2_0.8d38a655.ttf) format("truetype");
  font-weight: 600; }

* {
  box-sizing: border-box;
  font-family: "custom-Geogrotesque"; }

.home {
  background: #f0f0f0;
  height: 100vh;
  width: 100vw;
  overflow: hidden; }
  .home .intro {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: #18191A;
    opacity: 0.9;
    color: #fff;
    display: none;
    z-index: 1000; }
    .home .intro .intro-positioner {
      text-align: center;
      position: relative;
      z-index: 1000; }
    .home .intro .intro-title {
      font-size: 80px;
      font-weight: 400;
      line-height: 88px;
      margin: 0 0 24px 0;
      text-transform: uppercase; }
      @media (max-width: 480px) {
        .home .intro .intro-title {
          font-size: 45px;
          line-height: 50px; } }
      .home .intro .intro-title small {
        display: block;
        margin: 0 0 8px 0;
        font-size: 20px;
        line-height: 28px;
        color: #E13527;
        text-transform: uppercase;
        -webkit-animation-delay: 100ms;
                animation-delay: 100ms; }
      .home .intro .intro-title div {
        -webkit-animation-delay: 400ms;
                animation-delay: 400ms; }
    .home .intro .intro-body {
      -webkit-animation-delay: 600ms;
              animation-delay: 600ms; }
    .home .intro p {
      font-size: 24px;
      line-height: 32px;
      opacity: 0.7;
      margin: 0 auto 80px auto;
      width: 100%; }
      @media (max-width: 480px) {
        .home .intro p {
          margin-bottom: 30px;
          padding: 0 30px; } }
    .home .intro .play-icon {
      width: 120px;
      height: 120px;
      display: flex;
      margin: 0 auto 44px auto;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 3px solid rgba(216, 216, 216, 0.7);
      transition: all 100ms ease-in-out;
      cursor: pointer; }
      @media (max-width: 480px) {
        .home .intro .play-icon {
          width: 90px;
          height: 90px; }
          .home .intro .play-icon img {
            margin-left: 10px;
            width: 30px;
            height: auto; } }
      .home .intro .play-icon:hover {
        border-color: white;
        -webkit-transform: scale(1.06);
                transform: scale(1.06); }
    .home .intro a.video-link {
      color: #E13527;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 0 auto;
      text-decoration: none;
      text-transform: uppercase; }
      .home .intro a.video-link img {
        margin-left: 25px; }
    .home .intro .intro-backdrop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 110;
      background: rgba(33, 33, 33, 0.85); }
    .home .intro .image-wrapper {
      position: absolute; }
    .home .intro .nav-link {
      background: #E13527;
      color: white;
      height: 72px;
      line-height: 72px;
      text-transform: uppercase;
      padding: 0 60px;
      font-size: 18px;
      font-weight: 600;
      display: inline-block;
      text-decoration: none;
      position: relative;
      overflow: hidden; }
      .home .intro .nav-link .nav-link-text {
        transition: all 200ms 200ms ease-in-out; }
      .home .intro .nav-link .nav-link-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translate(-100%, -50%);
                transform: translate(-100%, -50%);
        transition: all 300ms ease-in-out; }
        .home .intro .nav-link .nav-link-arrow .nav-link-arrow-line {
          display: inline-block;
          width: 124px;
          height: 2px;
          background-size: 100% auto; }
        .home .intro .nav-link .nav-link-arrow .nav-link-arrow-icon {
          position: relative;
          margin-left: -10px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: -1;
          transition: all 200ms ease-in-out;
          opacity: 0; }
      .home .intro .nav-link:hover .nav-link-text {
        opacity: 0;
        transition: all 200ms ease-in-out; }
      .home .intro .nav-link:hover .nav-link-arrow {
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
        .home .intro .nav-link:hover .nav-link-arrow .nav-link-arrow-icon {
          transition: all 200ms 200ms ease-in-out;
          opacity: 1;
          z-index: 1; }
  .home .bottom-left-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 32px;
    left: 32px; }
  .home .action-button {
    height: 60px;
    white-space: nowrap;
    background: #EDEDED;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    cursor: pointer;
    padding: 0 24px;
    margin-right: 15px;
    color: inherit;
    text-decoration: none;
    border: 0; }
    .home .action-button img {
      height: 20px;
      width: 20px; }
    .home .action-button span {
      font-size: 18px;
      line-height: 60px;
      margin-left: 12px;
      color: #212121;
      display: inline-block;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
    .home .action-button:hover {
      background: #D6D6D6; }
    .home .action-button.button-back {
      position: absolute;
      top: 32px;
      left: 32px; }
    .home .action-button.button-without-icon span {
      margin-left: 0px; }
  .home .sidebar {
    background: #fff;
    position: absolute;
    top: 32px;
    right: 32px;
    bottom: 32px;
    width: 520px;
    padding: 60px;
    z-index: 10;
    display: flex;
    flex-direction: column; }
    .home .sidebar .loader {
      background-color: white;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      height: 2px;
      visibility: hidden;
      overflow: hidden; }
      .home .sidebar .loader.active {
        visibility: unset; }
    .home .sidebar .loader.active:before {
      display: block;
      position: absolute;
      content: "";
      left: -200px;
      width: 200px;
      height: 4px;
      background-color: #E13527;
      -webkit-animation: loading 1.5s linear infinite;
              animation: loading 1.5s linear infinite; }

@-webkit-keyframes loading {
  from {
    left: -200px;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

@keyframes loading {
  from {
    left: -200px;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }
    .home .sidebar .sidebar-content {
      flex: 1 1;
      overflow-y: scroll;
      margin-bottom: 20px; }
    .home .sidebar .sidebar-content-action .button {
      display: block;
      width: 100%;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      border: 0;
      outline: 0;
      box-shadow: 0;
      border-radius: 0;
      font: inherit;
      height: 60px;
      line-height: 60px;
      background: #E13527;
      color: #fff;
      font-size: 16px;
      cursor: pointer; }
  .home .navigation {
    position: absolute;
    top: 32px;
    bottom: 32px;
    width: 350px;
    right: 202px;
    background: #E13527;
    color: #fff;
    z-index: 2;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out; }
    .home .navigation .navigation-toggler {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      border: 0;
      outline: 0;
      box-shadow: 0;
      border-radius: 0;
      font: inherit;
      height: 60px;
      width: 60px;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform: translateX(-60px);
              transform: translateX(-60px);
      background: #E13527;
      transition: all 300ms ease-in-out; }
      .home .navigation .navigation-toggler .bar {
        display: block;
        width: 20px;
        height: 1px;
        background: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transition: all 300ms ease-in-out; }
        .home .navigation .navigation-toggler .bar:nth-child(1) {
          -webkit-transform: translate(-50%, -8px);
                  transform: translate(-50%, -8px); }
        .home .navigation .navigation-toggler .bar:nth-child(2) {
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
        .home .navigation .navigation-toggler .bar:nth-child(3) {
          -webkit-transform: translate(-50%, 7px);
                  transform: translate(-50%, 7px); }
    .home .navigation .navigation-list {
      list-style: none;
      margin: 60px 0 0 0;
      padding: 0; }
      .home .navigation .navigation-list li {
        display: block;
        margin: 0; }
        .home .navigation .navigation-list li a {
          display: flex;
          height: 104px;
          background: #E13527;
          align-items: center;
          padding: 0 44px;
          cursor: pointer;
          text-decoration: none; }
          .home .navigation .navigation-list li a .label {
            color: #fff;
            font-size: 18px; }
          .home .navigation .navigation-list li a .icon {
            width: 40px; }
        .home .navigation .navigation-list li.active a {
          background: #fff; }
          .home .navigation .navigation-list li.active a .label {
            color: #E13527; }
    .home .navigation.open {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%); }
      .home .navigation.open .navigation-toggler {
        -webkit-transform: translateX(0px);
                transform: translateX(0px);
        background: rgba(225, 53, 39, 0); }
        .home .navigation.open .navigation-toggler .bar {
          width: 26px; }
          .home .navigation.open .navigation-toggler .bar:nth-child(1) {
            -webkit-transform: translate(-50%, -50%) rotate(-45deg);
                    transform: translate(-50%, -50%) rotate(-45deg); }
          .home .navigation.open .navigation-toggler .bar:nth-child(2) {
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            opacity: 0; }
          .home .navigation.open .navigation-toggler .bar:nth-child(3) {
            -webkit-transform: translate(-50%, -50%) rotate(45deg);
                    transform: translate(-50%, -50%) rotate(45deg); }
  .home .architecture-list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden; }
    .home .architecture-list li {
      display: inline-block;
      width: 180px;
      margin-right: 40px;
      margin-bottom: 44px;
      cursor: pointer;
      position: relative; }
      .home .architecture-list li:nth-child(2n) {
        margin-right: 0; }
      .home .architecture-list li .active-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 120px;
        background: rgba(24, 25, 26, 0.5); }
        .home .architecture-list li .active-overlay img {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          width: 25px;
          height: 16px; }
      .home .architecture-list li img {
        width: 180px;
        height: 120px;
        margin-bottom: 12px; }
      .home .architecture-list li .label {
        text-align: center;
        font-size: 16px;
        color: #1E1E1E;
        line-height: 24px; }
      .home .architecture-list li.active .label {
        color: #E13527; }
  .home .image-wrapper {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0; }
    .home .image-wrapper .image {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      z-index: 1; }
  .home.show-intro .intro {
    display: flex; }
  .home .selected-product-name {
    margin: 0 0 15px 0;
    font-size: 18px;
    line-height: 24px; }
  .home .products-list {
    list-style: none;
    margin: 0;
    padding: 0; }
    .home .products-list li {
      display: block;
      margin: 0 0 16px 0;
      padding: 32px 50px 56px 36px;
      border: 1px solid rgba(225, 53, 39, 0.2);
      position: relative;
      cursor: pointer;
      color: #404040; }
      .home .products-list li .product-name {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 4px; }
      .home .products-list li .product-patterns-count {
        font-size: 16px;
        line-height: 24px; }
      .home .products-list li .product-arrow-right {
        position: absolute;
        bottom: 30px;
        right: 34px; }
      .home .products-list li.active {
        background: #E13527;
        border-color: #E13527;
        color: #fff; }
  .home .patterns-list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden; }
    .home .patterns-list li {
      margin: 0;
      display: flex;
      height: 100px;
      align-items: center;
      position: relative;
      cursor: pointer; }
      .home .patterns-list li .pattern-image {
        height: 64px;
        width: 64px;
        border-radius: 50%;
        margin-right: 24px; }
      .home .patterns-list li .active-overlay {
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background: rgba(24, 25, 26, 0.5);
        margin-top: -3px; }
        .home .patterns-list li .active-overlay img {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          width: 25px;
          height: 16px; }
      .home .patterns-list li .pattern-name {
        font-size: 18px;
        line-height: 24px; }
      .home .patterns-list li.active .pattern-name {
        color: #E13527; }
      .home .patterns-list li .closeup-button {
        height: 44px;
        margin-left: auto;
        background: #fff;
        border: 1px solid #E13527;
        padding: 0 20px;
        border-radius: 28px;
        position: relative;
        overflow: hidden;
        display: none; }
        .home .patterns-list li .closeup-button .closeup-button-text {
          transition: all 200ms 100ms ease-in-out;
          font-size: 16px;
          line-height: 44px;
          color: #404040;
          font-weight: 500; }
        .home .patterns-list li .closeup-button .closeup-button-arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 50%;
          left: 0;
          -webkit-transform: translate(-100%, -50%);
                  transform: translate(-100%, -50%);
          transition: all 300ms ease-in-out; }
          .home .patterns-list li .closeup-button .closeup-button-arrow .closeup-button-arrow-line {
            display: inline-block;
            width: 32px;
            height: 2px;
            background-size: 100% auto; }
          .home .patterns-list li .closeup-button .closeup-button-arrow .closeup-button-arrow-icon {
            position: relative;
            margin-left: -10px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: -1;
            transition: all 200ms ease-in-out;
            opacity: 0; }
        .home .patterns-list li .closeup-button:hover {
          background: #E13527; }
          .home .patterns-list li .closeup-button:hover .closeup-button-text {
            opacity: 0;
            transition: all 200ms ease-in-out; }
          .home .patterns-list li .closeup-button:hover .closeup-button-arrow {
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
            .home .patterns-list li .closeup-button:hover .closeup-button-arrow .closeup-button-arrow-icon {
              transition: all 200ms 200ms ease-in-out;
              opacity: 1;
              z-index: 1; }
      .home .patterns-list li:hover .closeup-button {
        display: block; }

.export-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6); }
  .export-modal-overlay .export-modal {
    width: 500px;
    border: 1px solid #cccccc;
    background: white;
    overflow: auto;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column; }
    .export-modal-overlay .export-modal .export-modal-content {
      text-align: center;
      margin-top: 25px;
      margin-bottom: 25px; }
      .export-modal-overlay .export-modal .export-modal-content div {
        padding: 15px; }
      .export-modal-overlay .export-modal .export-modal-content .export-header {
        color: #E13527;
        font-size: 22px;
        text-transform: uppercase; }
      .export-modal-overlay .export-modal .export-modal-content .export-options {
        display: flex;
        align-items: center;
        justify-content: center; }
        .export-modal-overlay .export-modal .export-modal-content .export-options .button {
          display: block;
          border: 0;
          outline: 0;
          box-shadow: 0;
          border-radius: 0;
          font: inherit;
          height: 60px;
          line-height: 60px;
          background: #E13527;
          color: #fff;
          font-size: 16px;
          min-width: 150px;
          cursor: pointer; }
          .export-modal-overlay .export-modal .export-modal-content .export-options .button:disabled {
            cursor: not-allowed; }
        .export-modal-overlay .export-modal .export-modal-content .export-options .button-pdf {
          margin-right: 25px; }

.closeup-modal-overlay {
  box-shadow: 0;
  outline: 0;
  border: 0; }
  .closeup-modal-overlay .close-modal {
    position: fixed;
    top: 0;
    right: 0;
    background: #E13527;
    z-index: 10000;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
  .closeup-modal-overlay .closeup-modal .closeup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0;
    z-index: 1000;
    outline: 0;
    border: 0;
    background-color: #fff;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }

@media (max-width: 480px) {
  .ReactModal__Content {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    padding: 0 !important; }
    .ReactModal__Content a {
      display: block;
      margin: 20px 20px -20px 20px; } }

