@font-face {
    font-family: "custom-Geogrotesque";
    src: url("fonts/2DF53C_0_0.eot");
    src: url("fonts/2DF53C_0_0.eot?#iefix") format("embedded-opentype"),url("fonts/2DF53C_0_0.svg") format("svg"),url("fonts/2DF53C_0_0.woff2") format("woff2"),url("fonts/2DF53C_0_0.woff") format("woff"),url("fonts/2DF53C_0_0.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "custom-Geogrotesque";
    src: url("fonts/2DF53C_1_0.eot");
    src: url("fonts/2DF53C_1_0.eot?#iefix") format("embedded-opentype"),url("fonts/2DF53C_1_0.svg") format("svg"),url("fonts/2DF53C_1_0.woff2") format("woff2"),url("fonts/2DF53C_1_0.woff") format("woff"),url("fonts/2DF53C_1_0.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "custom-Geogrotesque";
    src: url("fonts/2DF53C_2_0.eot");
    src: url("fonts/2DF53C_2_0.eot?#iefix") format("embedded-opentype"),url("fonts/2DF53C_2_0.svg") format("svg"),url("fonts/2DF53C_2_0.woff2") format("woff2"),url("fonts/2DF53C_2_0.woff") format("woff"),url("fonts/2DF53C_2_0.ttf") format("truetype");
    font-weight: 600;
}